import { Router } from '@reach/router';
import React from 'react';

import OrderDetailPage from '../../components/orders/OrderDetailPage';

const OrderDetail = () => {
  return (
    <Router basepath="/orders">
      <OrderDetailPage path="/:handle" />
    </Router>
  );
};

export default OrderDetail;
